import { TemplateFilters } from 'src/app/model/templates.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralTemplate } from '../model/templates.interface';
import { Pagination } from '../model/pagination.interface';
import { map } from 'rxjs/operators';
import { SetupService } from './setup.service';
import { Email } from '../classes/email.class';
import { UploadFilesService } from './upload-files.service';
import { IFile } from '../model/upload-files.model';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {

  constructor(
    private http: HttpClient,
    private setupService: SetupService,
    private uploadFilesService: UploadFilesService
  ) {}

  getAllTemplates(filters: TemplateFilters, page: number, perPage: number): Observable<Pagination<GeneralTemplate>> {
    let params = new HttpParams();

    params = params.append('page', page.toString());
    params = params.append('limit', perPage.toString());

    if (filters.search) {
      params = params.append('searchBy', filters.search);
    }

    if (filters.sortBy) {
      params = params.append('ownership', filters.sortBy);
    }

    if (filters.filter && filters.filter.length > 0) {
      params = params.append('type', filters.filter.join(','));
    }

    return this.http.get<Pagination<GeneralTemplate>>(
      `${environment.company}/${this.setupService.companyGuid}/templates`,
      { params }
    );
  }

  getJobTemplates(queryParams: any): Observable<GeneralTemplate[]> {
    let params = new HttpParams();
    params = params.append('type', 'job');

    if (queryParams.page) {
      params = params.append('page', queryParams.page.toString());
    } else {
      params = params.append('page', '1');
    }

    if (queryParams.limit) {
      params = params.append('limit', queryParams.limit.toString());
    }

    if (queryParams.category) {
      params = params.append('category', queryParams.category);
    }

    if (queryParams.language) {
      params = params.append('language', queryParams.language);
    }

    params = params.append('hasBusinessQuestions', '1');

    return this.http
      .get<Pagination<GeneralTemplate>>(
        `${environment.company}/${this.setupService.companyGuid}/templates`,
        { params }
      )
      .pipe(
        map(({data}: Pagination<GeneralTemplate>) => data)
      );
  }

  getSmsTemplate(templateId: number): Observable<GeneralTemplate> {
    return this.http.get<GeneralTemplate>(
      `${environment.smsTemplates}/${templateId}`
    );
  }

  createSmsTemplate(smsTemplate: GeneralTemplate): Observable<GeneralTemplate> {
    return this.http.post<GeneralTemplate>(environment.smsTemplates, smsTemplate);
  }

  editSmsTemplate(templateId: number, smsTemplate: GeneralTemplate): Observable<GeneralTemplate> {
    return this.http.put<GeneralTemplate>(
      `${environment.smsTemplates}/${templateId}`,
      smsTemplate
    );
  }

  getSmsTemplates(): Observable<GeneralTemplate[]> {
    return this.http
      .get(`${environment.company}/${this.setupService.companyGuid}/sms_templates`)
      .pipe(
        map(({data}: Pagination<GeneralTemplate>) => data)
      );
  }

  getEmailTemplate(templateId: number): Observable<GeneralTemplate> {
    return this.http.get<GeneralTemplate>(`${environment.emailTemplates}/${templateId}`)
      .pipe(
        map((template: GeneralTemplate) => {
          template.files = Email.setTemplateFiles(template.attachments);
          delete template.attachments;

          return template;
        }),
        switchMap((template: GeneralTemplate) => {
          if (!template.files.length) {
            return of(template);
          }

          return this.uploadFilesService.getFileSizes(template.files)
            .pipe(
              map((fileSizes: number[]) => {
                template.files
                  .forEach((file: IFile, index: number) => {
                    file.size = fileSizes[index];
                  });
                return template;
              })
          );
        }),
      );
  }

  createEmailTemplate(template: GeneralTemplate): Observable<GeneralTemplate> {
    if (template.greeting === '') {
      template.greeting = null;
    }

    return this.http.post<GeneralTemplate>(environment.emailTemplates, template);
  }

  editEmailTemplate(templateId: number, template: GeneralTemplate): Observable<GeneralTemplate> {
    if (template.greeting === '') {
      template.greeting = null;
    }

    return this.http.put<GeneralTemplate>(
      `${environment.emailTemplates}/${templateId}`,
      template
    );
  }

  getEmailTemplates(): Observable<GeneralTemplate[]> {
    return this.http
      .get(`${environment.company}/${this.setupService.companyGuid}/application_email_templates`)
      .pipe(
        map(({data}: Pagination<GeneralTemplate>) => data)
      );
  }

  deleteEmailTemplate(templateId: number): Observable<string> {
    return this.http.delete<string>(`${environment.emailTemplates}/${templateId}`);
  }

  deleteSMSTemplate(templateId: number): Observable<string> {
    return this.http.delete<string>(`${environment.smsTemplates}/${templateId}`);
  }

  deleteJobTemplate(templateId: number): Observable<string> {
    return this.http.delete<string>(`${environment.jobTemplates}/${templateId}`);
  }
}
