import { ICandidateApplication } from './application.interface';
import { Roles } from './role.interface';
import { TranslationKeys } from './translation-object.interface';
import { FileUpload, IFile } from './upload-files.model';
import { UserBranch } from './user.interface';

export interface VideoInterview {
  application: Partial<ICandidateApplication>;
}

export interface IInterview {
  id: number;
  guid: string;
  timeSlots: TimeSlot[];
  timeSlot: TimeSlot;
  status: InterviewStatus;
  candidateLinkToMeeting: string;
  employeeLinkToMeeting: string;
  createdVideo: string;
  senderCustomer: {id: number; guid: string};
  senderEnterpriseManager: {id: number; guid: string};
  cancellationReason: string;
  cancelledByEmployee?: boolean;
  autoCancelled?: boolean;
  subject: string;
  application?: IInterviewApplication;
  libraryVideo?: string;
  availableCustomers?: AvailableCustomerPerTimeslot[];
}

export interface IInterviewApplication {
  id: number;
  guid: string;
  candidateAppliedWithName: string;
  candidate: {
    guid: string;
  };
  job?: {
    company: {
      id?: number,
      isAllowedVideoInterview: boolean
      interviewTermsAndConditions?: string;
    };
    language: TranslationKeys;
    jobTitle: string;
    branch: { id: number };
    hiddenFromCustomers: HiddenFromCoustomer[];
    hiddenFromEnterpriseManagers: HiddenFromCoustomer[];
  }
  universalJob?: {
    company: {
      id?: number,
      isAllowedVideoInterview: boolean
      interviewTermsAndConditions?: string;
    };
    language: TranslationKeys;
    jobTitle: string;

  }
}

export interface InterviewEvent {
  files: FileUpload[];
  templateFiles: IFile[];
  interviewContent: InterviewContent;
}


export interface InterviewContent {
  timeSlots: TimeSlot[];
  subject: string;
  introForEmailInvitation: string;
  status: InterviewStatus;
  greeting?: string;
}

export interface ApplicationInterview {
  application: number;
  subject: string;
  introForEmailInvitation: string;
  timeSlots: {start: string, end: string}[];
  greeting?: string,
  attachments?: Record<string, string>;
}

export interface TimeSlot {
  start: Date;
  end: Date;
}

export enum InterviewStatus {
  created = 'created',
  pending = 'pending',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  finished = 'finished'
}

export interface IGroupInterviewParticipants {
  customers: number[];
  enterpriseManagers: number[];
  candidates: number[];
  thirdParties: string[];
}

export interface ICreatingGroupInterview extends IGroupInterviewParticipants{
  timeSlot: {start: string, end: string};
  job: number;
  subject: string;
  introForEmailInvitation: string;
  greeting: string;
  attachments?: Record<string, string>;
}

export interface IGroupInterviewForCandidate {
  groupInterview: CandidateGroupInterview;
  candidate: { email: string };
  status: InterviewStatus;
}

export interface CandidateGroupInterview {
  guid: string;
  timeSlot: TimeSlot;
  subject: string;
  job: {
    company: {isAllowedVideoInterview: boolean, interviewTermsAndConditions?: string},
    language: TranslationKeys
  };
  creatorCustomer?: { name: string };
  creatorEnterpriseManager?: { name: string };
}

export interface IGroupInterview {
  guid: string;
  timeSlot: TimeSlot;
  status: InterviewStatus;
  createdVideo: string;
  cancellationReason: string;
  subject: string;
  creatorCustomer: { id: number; name: string; guid: string };
  creatorEnterpriseManager: { id: number; name: string; guid: string };
  customersInGroupInterview: ICustomerInGroupInterview[];
  enterpriseManagersInGroupInterview: ICustomerInGroupInterview[];
  candidatesInGroupInterview: ICandidateInGroupInterview[];
  thirdPartiesInGroupInterview: IThirdParitesInGroupInterview[];
  job: {
    guid: string,
    jobTitle: string,
    company: { id: number, isAllowedVideoInterview: boolean },
    branch: { id: number };
    hiddenFromCustomers: HiddenFromCoustomer[];
    hiddenFromEnterpriseManagers: HiddenFromCoustomer[];
  };
  libraryVideo?: string;
  availableCustomers?: AvailableCustomerPerTimeslot[];
}

export interface HiddenFromCoustomer {
  id: number;
  guid: string;
  name: string;
}
export interface IGroupParticipant {
  guid: string;
  linkToInterview: string;
  status: InterviewStatus;
  invitationEmailSent: boolean;
}

export interface ICustomerInGroupInterview extends IGroupParticipant {
  employee: { id: number, name: string };
}

export interface ICandidateInGroupInterview extends IGroupParticipant {
  candidate: { guid: string, id: number, email: string, name?: string };
}

export interface IThirdParitesInGroupInterview extends IGroupParticipant {
  email: string;
}

export interface InterviewTab {
  active: boolean;
  tabTitle: string;
  isGroup: boolean;
}

export enum TimeParametersInMs {
  second = 1000,
  minute = second * 60,
  hour = minute * 60,
  day = hour * 24
}

export type MsgRequestType = `${MsgReq}`;

export enum MsgReq {
  message = 'message',
  joinRequest = 'joinRequest'
}
export interface RaiseHand {
  uuid: string;
  handRaised: boolean;
}

export interface ParticipantMediaOptions {
  type: ParticipantMediaOptionsType;
  uuid: string;
  value: boolean;
}

export enum ParticipantMediaOptionsType {
  audio = 'audio',
  video = 'video'
}

export interface InterviewAction {
  type: InterviewActionType;
  value?: boolean;
}

export enum InterviewActionType {
  disconnect = 'disconnect',
  waitHost = 'waitHost'
}

export interface AvailableCustomerPerTimeslot {
  branches?: UserBranch[];
  guid: string;
  name: string;
  roles?: Roles[];
  allowedUniversalJob?: boolean;
}

